<!-- TODO: upgrade to vue3 -->
<!-- TODO: use tailwind -->
<!-- TODO: if admin, show all projects (with indicators) -->
<!-- TODO: fix projects not showing up in inactive hacakathons -->
<!-- TODO: account for team lead in "join team" logic -->
<template>
    <div id="app">
        <DsCard
            v-if="isUnverifiedUser"
            class="unverified-alert"
        >
            <h3>Verify your email</h3>

            <p>Please verify your email address <strong>{{ user.email }}</strong> by checking your inbox and logging in again.</p>

            <div v-if="verificationEmailSent">
                <DsFilledButton @click="logout">
                    Login
                </DsFilledButton>
            </div>

            <template v-else>
                <DsFilledButton @click="sendEmailVerification">
                    Send verification email
                </DsFilledButton>

                <DsTextButton gray dense @click="logout">
                    Try a different email
                </DsTextButton>
            </template>
        </DsCard>

        <template v-else>
            <NavHeader v-if="authorizedRoute" />

            <div v-if="loading" class="loading-spinner centered">
                <DsSpinner />
            </div>

            <main>
                <router-view />
            </main>
        </template>
    </div>
</template>

<script>
import NavHeader from '@/components/NavHeader';
import { mapState, mapGetters } from 'vuex';
import firebase from 'firebase/compat/app';

export default {
    components: {
        NavHeader,
    },

    data() {
        return {
            loading: false,
            verificationEmailSent: false,
        };
    },

    mounted() {
        if (this.user.uid) this.load();
    },

    watch: {
        // eslint-disable-next-line
        ['user.uid'](value) {
            if (value) this.load();
        },
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            guests: ({ users }) => users.guests,
        }),

        ...mapGetters({
            isGuest: 'users/isGuest',
        }),

        authorizedRoute() {
            return !['login', 'logout', 'forbidden'].includes(this.$route.name);
        },

        isUnverifiedUser() {
            return this.user && !this.user?.emailVerified && this.user?.providerData?.[0]?.providerId === 'password';
        },
    },

    methods: {
        async logout() {
            await firebase.auth().signOut();

            this.$store.commit('auth/SET_USER', { email: '' });
            this.$store.commit('auth/SET_GOOGLE_TOKEN', '');
            this.$router.push({ name: 'login' });
        },

        async sendEmailVerification() {
            await firebase.auth().currentUser.sendEmailVerification();

            this.verificationEmailSent = true;
        },

        async load() {
            this.loading = true;

            try {
                await Promise.all([
                    this.$store.dispatch('users/LOAD_USERS'),
                    this.$store.dispatch('projects/LOAD_PROJECTS'),
                    this.$store.dispatch('hackathons/LOAD_HACKATHONS'),
                    this.$store.dispatch('hackathons/LOAD_HACKATHON_DEMO'),
                    this.$store.dispatch('hackathons/LOAD_HACKATHON_COMMENTS'),
                    this.$store.dispatch('tags/LOAD_TAGS'),
                ]);

                this.loading = false;
            } catch (error) {
                this.loading = false;

                if (this.$route.name !== 'login') this.$router.replace({ name: 'login' });
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss">
body {
    background-color: $color-gray-200;
}
</style>

<style lang="scss" scoped>
#app {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 1280px;
    max-width: 100%;
}

main {
    padding: 0 $spacing-200 $spacing-200;
}

.unverified-alert {
    width: 500px;
    margin: $spacing-300 auto 0;
    padding: $spacing-300;

    h3 {
        margin-bottom: $spacing-200;
        font-weight: 500;
    }
}
</style>
